@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/colors';

.root {
  display: flex;
  align-items: center;
  width: min-content;
  height: 16px;
  padding-right: spacings.$s;

  .infoIcon {
    font-size: 16px;
    color: colors.$foundation-backgroundTertiary;
  }

  .label {
    margin-right: spacings.$xs;
    white-space: nowrap;
    color: colors.$foundation-backgroundTertiary;
  }
}
.button {
  border: none;
  background: none;
  padding: 0;
}
.body {
  a,
  [role='link'] {
    display: contents;
    text-decoration: underline;
  }
}
