@import '@/core/tamagoshi/scss';
@import '../../../../variables';

.root.promoted {
  @include size-above($productCardSmallDesktopPromotedMediaQuery) {
    width: $productCardSmallDesktopImageSize;
    height: $productCardSmallDesktopImageSize;
  }

  @include size-above($productCardLargeDesktopPromotedMediaQuery) {
    width: $productCardLargeDesktopImageSize;
    height: $productCardLargeDesktopImageSize;
  }
}
