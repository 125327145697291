@import '../../../scss';
@import '../../variables';

.root {
  padding: 0;
  font-size: large;
  cursor: pointer;
  appearance: none;
  border: 0;
  background: none;
}

.disabled {
  color: $disabledColorForCaret;
  cursor: auto;
}

.icon {
  min-width: $controlIconSize;
  min-height: $controlIconSize;
  vertical-align: middle;
}
