@use '@/core/tamagoshiv2/tokens/colors';

.loader {
  --dot-color: #{colors.$foundation-textIconPrimary};
  align-items: center;
  column-gap: 4px;
  display: flex;
  justify-content: center;
}

.dot {
  $size: 8px;
  animation: dot 600ms 0ms infinite alternate cubic-bezier(0.45, 0, 0.55, 1);
  background-color: var(--dot-color);
  border-radius: 50%;
  height: $size;
  width: $size;

  &:nth-child(2) {
    animation-delay: 200ms;
  }

  &:nth-child(3) {
    animation-delay: 400ms;
  }
}

@keyframes dot {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
