@import '@/core/tamagoshi/scss';
@import '@/retention/Homepage/styles/carousel';
@import '@/retention/Homepage/styles/section';

.root {
  @extend %section;
  padding-bottom: $spacingXXL1;
}

.carousel {
  @extend %carousel;
}

.carouselControls {
  @extend %carouselControls;
}

.carouselSlider {
  @extend %carouselSlider;
}

.carouselTitle {
  @extend %carouselTitle;
}

.fullFamily {
  @extend %carouselCard;
  white-space: nowrap;

  &:not(:last-child) {
    padding-right: $spacingMd;
    border-right: 1px solid $n300;
  }
}

.family {
  display: block;
  text-align: left;

  @include text('body1', 'bold');
  color: $n800;
  text-decoration: underline;
}

.ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.item {
  margin: 8px 8px 8px 0;
  text-align: left;
}

.url {
  @include text('body2', 'semiBold');
  color: $n800;
  text-decoration: underline;
}

@include size-below('md') {
  .fullFamily:not(:first-child) {
    margin-left: $spacingMd;
  }
}
