@import '@/core/tamagoshi/scss';

.tertiaryCampaignsBlock {
  @include size-above($mdWidth) {
    display: none;
  }

  @include text('h4', 'bold');
  height: 100%;
  width: 100%;
  background-color: $n0;
  color: $n800;
  margin-top: $spacingLg;
  margin-bottom: $spacingLg;
  padding: $spacingMd;
}

.campaignTitle {
  display: inline-block;
  width: 100%;
}

.campaignSubtitle {
  @include text('body2');

  color: $n700;
  display: inline-block;
  margin-bottom: $spacingMd;
  margin-top: $spacingXs;
  width: 100%;
}

.tertiaryCampaigns {
  @include size-below('md') {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
  }
}
