@import '../scss';
@import './variables';

.root {
  display: grid;
  grid-template-areas:
    'above above above'
    'main main retail'
    'secondary pro pro';
  width: min-content;

  &.onlyPro {
    grid-template-areas:
      'above above above'
      'main main retail'
      'pro pro pro';
  }

  &:not(.placeholderDisabled):not(.above):before {
    content: '';
    display: block;
    grid-area: above;
    height: $detailedPriceAboveHeight;
  }

  &:not(.placeholderDisabled):not(.secondaryDisplayed):after {
    content: '';
    display: block;
    grid-area: secondary;
    height: $detailedPriceSecondaryHeight;
    margin-top: $spacingXXs;
  }

  .retail {
    display: flex;
    grid-area: retail;
    margin-left: $spacingXXs;
    align-self: end;
    color: $primaryColorDark;
  }

  &.retailAbove .retail {
    grid-area: above;
    margin: 0;
    line-height: $detailedPriceAboveHeight;
    height: $detailedPriceAboveHeight;
  }

  .main {
    display: flex;
    position: relative;
    grid-area: main;
  }

  .secondary {
    display: flex;
    grid-area: secondary;
    margin-top: $spacingXXs;
    width: auto;
    height: $detailedPriceSecondaryHeight;
    color: $primaryColorDark;
  }

  .pro {
    grid-area: pro;
    &:nth-child(3) {
      margin-left: $spacingXs;
    }
    margin-top: $spacingXXs;
    height: $detailedPriceAboveHeight;
  }
}
