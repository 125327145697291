@use './palette';

@mixin initialize {
  .b2b {
    --mm-color-accent-background-primary: #{palette.$V500};
    --mm-color-accent-background-primaryActive: #{palette.$V600};
    --mm-color-accent-background-primaryHover: #{palette.$V300};
    --mm-color-accent-background-secondary: #{palette.$V100};
    --mm-color-accent-border-primary: #{palette.$V500};
    --mm-color-accent-textIcon-primary: #{palette.$V500};
  }

  .b2c {
    --mm-color-accent-background-primary: #{palette.$Ba400};
    --mm-color-accent-background-primaryActive: #{palette.$Ba600};
    --mm-color-accent-background-primaryHover: #{palette.$Ba300};
    --mm-color-accent-background-secondary: #{palette.$Ba100};
    --mm-color-accent-border-primary: #{palette.$Ba400};
    --mm-color-accent-textIcon-primary: #{palette.$Ba400};
  }
}

$backgroundPrimary: var(--mm-color-accent-background-primary, palette.$Ba400);
$backgroundPrimaryActive: var(--mm-color-accent-background-primaryActive, palette.$Ba600);
$backgroundPrimaryHover: var(--mm-color-accent-background-primaryHover, palette.$Ba300);
$backgroundSecondary: var(--mm-color-accent-background-secondary, palette.$Ba100);
$borderPrimary: var(--mm-color-accent-border-primary, palette.$Ba400);
$textIconPrimary: var(--mm-color-accent-textIcon-primary, palette.$Ba400);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundPrimaryActive: $backgroundPrimaryActive;
  backgroundPrimaryHover: $backgroundPrimaryHover;
  backgroundSecondary: $backgroundSecondary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}
