@import '../DetailedPrice/variables';
@import '../scss';
@import './variables';

.addToCartWrapper {
  margin-top: $spacingXs;
  margin-bottom: $spacingXs;
}

.visualDetails {
  flex: initial;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: $spacingXs;
  overflow: hidden;
}

.informationLine {
  margin-top: $productCardMinSpaceBetweenElements;
}

.merchandising + .title {
  margin-top: 1px;

  + .informationLine {
    margin-top: $productCardMinSpaceBetweenElements - 1;
  }
}

.ratings {
  margin-top: $productCardMaxSpaceBetweenElements;
}

.price {
  margin-top: $productCardMinSpaceBetweenElements;
  font-size: 24px;
  color: $primaryColorDark;
}

.root {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: $spacingMd;
  min-width: $productCardMinWidth;
  max-width: $productCardMaxWidth;
  max-height: $productCardMaxHeight;
  border: $productCardBorderSize solid $productCardBorderColor;
  border-radius: $productCardBorderRadius;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease;
  box-sizing: border-box;
  background-color: $productCardBackgroundColor;
  text-decoration: none;
  outline: none;

  @media (hover) {
    &:hover {
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
    }

    &:focus {
      transition: none;

      &:not(:active) {
        box-shadow: 0 0 2px $productCardBorderColorFocus;
        border: $productCardBorderSize solid $productCardBorderColorFocus;
      }
    }
  }

  // This is the placeholder when there isn't any delivery information displayed
  &.deliveryPlaceholder:not(.vertical) .contentContainer:after {
    content: '';
    display: block;
    min-height: $spacingMd;
    width: 100%;
    margin-top: $spacingXs;
  }

  &.promoted,
  &.vertical {
    .contentContainer {
      margin-left: 0;
    }
  }

  &.promoted {
    flex-direction: column;
    max-height: $productCardMaxHeight * 2;

    .contentContainer {
      margin-top: $spacingMd;
    }

    .title {
      margin-top: 2px;
      max-width: $productCardTitleMaxWidth; // mainly used for large desktop promoted
    }

    .addToCart {
      position: absolute;
      right: $spacingMd;
      bottom: $spacingMd;
    }

    @include size-above($productCardSmallDesktopPromotedMediaQuery) {
      flex-direction: row;
      width: auto;
      max-width: inherit;

      .contentContainer {
        margin-top: 0;
        margin-left: $spacingMd;
      }
      .title {
        margin-top: 0;
      }
    }

    @include size-above($productCardLargeDesktopPromotedMediaQuery) {
      .contentContainer {
        max-width: 690px;
      }
    }
  }

  &.vertical {
    flex-direction: column;
    min-width: $productCardVerticalWidth;
    max-width: $productCardVerticalWidth;
    max-height: 100%;
    height: 100%;
    flex: 1;

    .contentContainer {
      margin-top: $spacingXs;
    }

    .addToCartWrapper {
      margin-top: $spacingSm;
      margin-bottom: 0;
    }
  }

  &.light {
    padding-top: $spacingXs;
    padding-bottom: $spacingXs;

    .visualDetails {
      margin-top: 0;
      margin-bottom: 0;
    }

    .contentContainer {
      margin-top: $productCardLightMargin;
    }

    .title {
      margin-top: 0;
    }

    .informationLine {
      margin-top: 0;
      height: 0;
    }

    .ratings {
      margin-top: $productCardLightMargin;
    }

    .price {
      margin-top: 0;
    }

    &.vertical {
      padding-bottom: $spacingMd;

      .contentContainer:before {
        content: '';
        display: block;
        height: $productCardPromoTagHeight;
      }

      .contentContainer.hasTag:before {
        display: none;
      }

      .price {
        min-height: 22px;
      }

      .ratings {
        margin-bottom: $productCardLightMargin;
      }

      .title {
        line-height: $productVerticalLightCardTitleLineHeight;
        height: $productVerticalLightCardTitleLineHeight * 2;
      }
    }
  }
}

.isPromo {
  color: $r600;
}

.timeLeftLabel {
  margin-top: -$detailedPriceSecondaryHeight;
}

.pro {
  align-self: flex-end;
  margin-bottom: 1px;
}

.rank {
  margin-top: 25px;
}

.rankLabel {
  z-index: 1;
  position: absolute;
  top: -24px;
  left: 0;
}

.secondHandTag {
  position: absolute;
  z-index: 1;
  top: $spacingXs;
  left: $spacingXs;
}

.delivery {
  align-self: start;
  height: 16px;
}
