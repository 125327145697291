.root.retail {
  display: inline-flex;
  position: relative;
  font-size: 0.5em;
  text-decoration: line-through;
  line-height: 1.33em;
}

.tax {
  line-height: 1em;
  text-decoration: line-through;
}
