@import '../../../scss';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  min-width: 48px;
  font-size: $textBody1FontSize;
}

.success {
  align-self: center;
  color: $g800;
}

.error {
  color: $r700;
}

.text {
  margin-left: $spacingXs;
  font-weight: 600;
  font-size: 16px;
}
