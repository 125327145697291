@import '../../../scss';

.root {
  height: $spacingMd;

  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
