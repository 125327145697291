@use '@/core/tamagoshiv2/tokens/spacings';
@import '@/retention/Homepage/styles/carousel';
@import '@/retention/Homepage/styles/section';

.root {
  // stylelint-disable-next-line color-no-hex
  padding-bottom: spacings.$xl;
  padding-top: spacings.$xl;
}

.carousel {
  @extend %carousel;
}

.carouselCard {
  @extend %carouselCard;
  $width: 296px;
  flex: 0 0 $width;
  min-height: 332px;
  max-width: $width;
  width: $width;
}

.carouselControls {
  @extend %carouselControls;
}

.carouselSlider {
  @extend %carouselSlider;
}

.carouselTitle {
  @extend %carouselTitle;
}

.section {
  @extend %section;
}
