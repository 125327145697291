@import '../scss';

.secondHandTag {
  display: inline-flex;
  width: min-content;
  height: 16px;
  white-space: nowrap;
  align-items: center;
  background-color: $v200;
  color: $ba800;
  border-radius: $spacingXXs;
  box-sizing: content-box;
  padding: $spacingXXs $spacingXs;

  @include text('small', 'bold');

  svg {
    margin-right: $spacingXXs;
    margin-left: 0;
    height: 14px;
    width: 10px;
    color: $ba800;
  }

  &.small {
    @include text('tiny', 'bold');
    padding: 0 6px;

    svg {
      width: 8px;
      height: 11px;
    }
  }
}
