@import '../../../scss';
@import '../../variables';
@import './variables';

.logos {
  position: absolute;
  display: flex;
  align-items: center;
  height: 16px;
  bottom: 0;
  left: 0;

  :not(:first-child) {
    margin-left: $spacingXXs;
  }

  .brand {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 16px;
    object-fit: contain;
  }

  &.withOneTag {
    .brand {
      max-width: calc(100% - #{$tagSize + $spacingXXs});
    }
  }

  &.withFlagAndEcoTag {
    .brand {
      max-width: calc(
        100% - #{$tagSize + $tagSize + $spacingXXs + $spacingXXs}
      );
    }
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.promoted,
  &.light {
    .logos {
      bottom: 0;
      left: 0;
    }
  }

  &.vertical {
    align-items: center;
    margin-top: $spacingXs;

    .logos {
      margin-top: $spacingXs;
      align-self: start;
    }

    &:not(.light) {
      .withoutBrand {
        margin-bottom: $spacingLg;
      }

      .logos {
        position: relative;
      }
    }
  }

  &.promoted {
    @include size-above($productCardSmallDesktopPromotedMediaQuery) {
      max-width: $productCardSmallDesktopImageSize;
    }

    @include size-above($productCardLargeDesktopPromotedMediaQuery) {
      max-width: $productCardLargeDesktopImageSize;
    }
  }

  &.light {
    .brand {
      margin-top: 0;
    }
  }
}
