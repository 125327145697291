@use 'sass:math';
@import '../scss';

$productCardTitleMaxHeight: 55px;
$productCardTitleLineHeight: math.div($productCardTitleMaxHeight, 3);
$productCardTitleColor: $primaryColorDark;
$productCardTitleMaxWidth: 690px;
$productCardMinSpaceBetweenElements: 2px;
$productCardMaxSpaceBetweenElements: $spacingXs;
$productCardBorderRadius: 4px;
$productCardBorderColor: $n300;
$productCardBorderSize: 1px;
$productCardBorderColorHover: $n700;
$productCardBorderColorFocus: #12bbff; // stylelint-disable-line color-no-hex
$productCardBorderSizeFocus: 6px;
$productCardBackgroundColor: $n0;
$productCardMinWidth: 320px;
$productCardMaxWidth: 341px;
$productCardMaxHeight: 245px;
$productCardNarrowWidth: 295px;
$productCardVerticalWidth: 210px;
$productCardNarrowVerticalWidth: 160px;
$productCardLightMargin: 4px;
$productCardSpacing: $spacingMd;
$productCardFilterPanel: 344px;
$productCardSpacingBetweenCard: $spacingMd;
$productCardSpacingListing: $spacingLg;
$productCardSmallDesktopPromotedMediaQuery: (2 * $productCardMinWidth) +
  $productCardSpacing * 2;
$productCardLargeDesktopPromotedMediaQuery: (3 * $productCardMinWidth) + 2 *
  ($productCardSpacingBetweenCard + $productCardSpacingListing) +
  $productCardFilterPanel;
$productCardSmallDesktopImageSize: 162px;
$productCardLargeDesktopImageSize: 197px;
$productCardPromoTagHeight: 18px;
$productVerticalLightCardTitleLineHeight: 18px;
