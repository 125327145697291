@use '@/core/tamagoshiv2/tokens/colors';
@use '@/core/tamagoshiv2/tokens/radius';
@use '@/core/tamagoshiv2/tokens/spacings';
@use '@/core/tamagoshiv2/tokens/typography';

.button {
  align-items: center;
  appearance: none;
  border-radius: radius.$card;
  border: none;
  display: flex;
  justify-content: center;
  text-decoration: none;
  transition: all 100ms ease-in-out;

  &:not(.disabled) {
    cursor: pointer;
  }

  &:not(.loading).disabled {
    color: colors.$foundation-textIconDisabled;
  }
}

.label {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

svg + .label,
.label + svg {
  margin-left: spacings.$xs;
}

.hugContent,
.hugContentCentered {
  max-width: 328px;
  width: min-content;
}

.hugContentCentered {
  margin-left: auto;
  margin-right: auto;
}

.fillContainer {
  width: 100%;
}

.disabled {
  pointer-events: none;
}

.loading > *:not(.loader) {
  opacity: 0;
  visibility: hidden;
}

.loader {
  position: absolute;
}

.sizeL,
.sizeM {
  padding: 0 spacings.$m;

  > *:not(.label, .loader) {
    height: 24px;
    width: 24px;
  }
}

.sizeL {
  min-height: 48px;
}

.sizeM {
  min-height: 40px;
}

.primary {
  background-color: colors.$accent-backgroundPrimary;
  color: colors.$foundation-textIconPrimary;

  &:not(.disabled) {
    &:hover {
      background-color: colors.$accent-backgroundPrimaryHover;
    }

    &:active,
    &:focus {
      background-color: colors.$accent-backgroundPrimaryActive;
    }
  }

  &:not(.loading).disabled {
    background-color: colors.$foundation-backgroundDisabled;
    border: none;
  }
}

.secondary {
  background-color: colors.$foundation-backgroundInverted;
  border: 1px solid colors.$foundation-borderTertiary;
  color: colors.$foundation-textIconPrimary;

  &:not(.disabled) {
    &:hover {
      background-color: colors.$foundation-backgroundDisabled;
    }

    &:active,
    &:focus {
      background-color: colors.$foundation-backgroundSecondary;
    }
  }

  &:not(.loading).disabled {
    background-color: colors.$foundation-backgroundDisabled;
    border: none;
  }
}

.tertiary {
  background-color: colors.$foundation-backgroundInverted;
  border: 1px solid colors.$foundation-borderSecondary;
  color: colors.$foundation-textIconPrimary;

  &:not(.disabled) {
    &:hover {
      background-color: colors.$foundation-backgroundDisabled;
    }

    &:active,
    &:focus {
      background-color: colors.$foundation-backgroundSecondary;
    }
  }

  &:not(.loading).disabled {
    background-color: colors.$foundation-backgroundDisabled;
    border: none;
  }
}

.link {
  align-items: inherit;
  background-color: transparent;
  color: colors.$foundation-textIconPrimary;
  justify-content: center;
  min-height: auto;
  padding: 0;
  text-decoration: underline;

  &:not(.loading) {
    justify-content: flex-start;
  }

  &.hugContent,
  &.hugContentCentered {
    width: auto;
  }

  .label {
    white-space: initial;
  }

  &:not(.disabled) {
    &:hover {
      color: colors.$info-textIconPrimary;
    }

    &:active,
    &:focus {
      color: colors.$brand-textIconPrimary;
    }
  }

  &:not(.loading).disabled {
    border: none;
    color: colors.$foundation-textIconSecondary;
  }
}
