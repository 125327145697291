@import '../scss';

$height: 16px;

.root {
  height: $height;
  display: flex;
}

.logo {
  margin-left: $spacingXXs;
}
