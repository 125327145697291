.addToCartWrapper {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .addToCart {
    margin: 0;
  }
}
