@use '@/core/tamagoshiv2/tokens/spacings';
@import '@/retention/Homepage/styles/carousel';
@import '@/retention/Homepage/styles/section';

.root {
  @extend %section;
  padding-top: spacings.$xl;
  padding-bottom: spacings.$xl;
}

.carouselTitle {
  @extend %carouselTitle;
}
