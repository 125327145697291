@import '../../../scss';

.root {
  margin-top: $spacingXs;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.emptySpan {
  margin-top: $spacingXs;
  height: $spacingMd;
}
