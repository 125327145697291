@import '../../../scss';

$textColor: $n0;
$backgroundColor: $b500;

.root {
  display: flex;
  height: 20px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;

  @include text('small', 'semiBold');

  color: $textColor;
  background-color: $backgroundColor;
  border-radius: 2px;
}
