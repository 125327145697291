/* stylelint-disable declaration-no-important */
@import '@/core/tamagoshi/scss';

$sizes: (
  'xxs': $spacingXXs,
  'xs': $spacingXs,
  'sm': $spacingSm,
  'md': $spacingMd,
  'lg': $spacingLg,
  'xl': $spacingXl,
);
$controlsWidth: 48px;
$opacityStretch: 5%;

.root {
  display: grid;
  width: 100%;
  grid-template-areas:
    'title controls'
    'slider slider';
  grid-template-columns: 1fr auto;

  @each $key, $spacing in $sizes {
    &.horizontal-#{$key} {
      padding-left: $spacing;

      .slider {
        width: calc(100% + #{$spacing});
        margin-left: -$spacing;
        scroll-padding: $spacing;

        // stylelint-disable-next-line max-nesting-depth
        &:after {
          display: block;
          content: '';
          min-width: $spacing;
        }

        // stylelint-disable-next-line max-nesting-depth
        > *:first-of-type {
          margin-left: $spacing;
        }
      }
    }
  }
}

.title {
  grid-area: title;
}

.controls {
  grid-area: controls;
  align-items: flex-start;

  display: flex;
  flex-flow: row nowrap;
}

.slider {
  grid-area: slider;
  // NOTE Prevent browser history motions within the carousel.
  //      Be aware that if you hide controls by default: for instance because
  //      you toggle them back on hover, they won't be visible for mobile users
  //      but will still prevent horizontal scroll if the user swipes directly
  //      on the control buttons.
  //      You can circumvent the issue with `pointer-events: none` or `display:
  //      none` for mobile users since they are not usable anyway.
  overscroll-behavior-x: contain;
}

.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $primaryColorDark;
  }
}

.withSideArrowsRoot {
  grid-template-areas:
    'title title title'
    'controls1 slider controls2';
  grid-template-columns: 58px 1fr 58px !important;

  @include size-below($mdWidth) {
    grid-template-areas:
      'title'
      'slider';
    grid-template-columns: 1fr !important;
  }
}

.withSideArrowsTitle {
  grid-area: title;
}

.controlLeft {
  grid-area: controls1;
  margin-right: auto;
}

.controlRight {
  grid-area: controls2;
  margin-left: auto !important;
}

.withSideArrowsButton {
  border: 1px solid $n300;
  background-color: $n0;
  border-radius: 40px;
  width: 48px;
  height: 48px;

  margin: auto 0;

  @include size-below($mdWidth) {
    display: none;
  }
}

.withSideArrowsHover {
  &:hover {
    cursor: pointer;
    border-color: $n900;
  }
}
