@import '../../../Button/variables';
@import '../../../scss';
@import '../../variables';

$buttonWidth: 48px;
$buttonHeight: 48px;

.root {
  min-height: $buttonHeight;
  min-width: $buttonWidth;
  padding: 0 $buttonPadding;
  display: flex;
  align-items: center;

  .iconButton {
    display: none;
  }

  &.displayMobileIcon {
    .iconButton {
      display: inline-block;
    }

    &:not(.narrow) {
      text {
        display: none;
      }
    }
  }

  &.narrow {
    .text {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
