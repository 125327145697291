@import '../../scss/index';

.root {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  font-size: 1em;
  line-height: 1em;
  font-family: $fontFamily;
  white-space: nowrap;

  .decimal {
    display: block;
    order: 3;
  }

  .currency {
    display: block;
  }

  sup {
    display: flex;
    margin-top: -0.2em;
    font-size: 0.583333em;
    line-height: unset;
    top: unset;

    &.tax {
      font-size: 0.7em;
    }
  }

  .tax {
    order: 3;
    white-space: nowrap;
  }

  .taxSmallSize {
    font-size: 0.47em;
    margin-top: 1.2em;
    line-height: 0.5em;
  }

  .tax:before {
    content: '\00a0';
  }

  .unit {
    order: 4;
  }

  &.euro {
    .integer {
      order: 1;
    }

    sup:not(.tax),
    .currency {
      order: 2;
    }
  }

  &.poundSterling {
    .currency {
      order: 1;
    }

    .integer {
      order: 2;
    }

    .decimal:not(:empty):before {
      content: '.';
    }
  }

  &.currencyEnd {
    .integer {
      order: 1;
    }

    .decimal {
      order: 2;
      &:not(:empty):before {
        content: ',';
      }
    }

    .currency {
      order: 3;
    }
  }
}
