@import '../Skeleton/Skeleton.module';

.line {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0.2em;
    left: 0;
    right: 0;

    @extend %skeleton;
    height: 1em;
    width: 100%;

    @extend %themeDefault;
  }

  &:last-child:not(:first-child):before {
    width: 90%;
  }
}
