@use '@/core/tamagoshiv2/tokens/spacings';
@use '@/core/tamagoshiv2/tokens/typography';
@import '../../../scss';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .inStock {
    margin-right: $spacingXXs;
  }
  .item {
    margin-right: 2px;
    margin-bottom: 2px;
  }
  .isSponsoredAndCouponOrPromo {
    margin-left: $spacingXXs;
  }
  .loweredPrice {
    gap: spacings.$xs;
  }

  .loweredPrice svg {
    height: typography.$fontSizeBody1;
    width: typography.$fontSizeBody1;
    margin-right: unset;
    align-self: unset;
  }
}
