@use 'sass:math';
@import '../../variables';

.root {
  color: $productCardTitleColor;
  width: 100%;
  max-width: 100%;
  height: $productCardTitleMaxHeight;
  line-height: $productCardTitleLineHeight;
  /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: left;
}

.root.shortTitle {
  height: $productCardTitleLineHeight * 2;
  -webkit-line-clamp: 2;
}
